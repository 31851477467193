<template>
    <div>
        <div class="control has-top-border">
          <h1 class="title mt-3">Payment Settings</h1>
        </div>
        <div class="columns is-multiline mb-0 mt-3">
            <div class="ml-5" v-if="authViaLineIntegration">
                <p class="mb-3">AuthVia Line Integration Id</p>
                <p>{{authViaLineIntegration.id}}</p>
            </div>
            <div class="ml-5" v-else>
                <button :class="isLoading ? 'button is-primary is-loading' : 'button is-primary'" @click="createAuthViaLineIntegration">
                    Generate AuthVia Line Integration
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { secureGet, securePost } from '../secureFetch.js';

    export default {
        name: 'AuthViaLineSettings',
        props: {
            lineId: {
                type: String,
                default: null
            },
        },
        computed: {
            hostUrl: function () {
                return this.$store.state.hostUrl;
            },
        },
        data() {
            return {
                errorMessage: "",
                defaultErrorMessage: "Something didn't work.",
                isLoading: false,
                authViaLineIntegration: null,
            }
        },
        mounted() {
            this.getAuthViaLineIntegration();
        },
        methods: {
            createAuthViaLineIntegration() {
                this.isLoading = true;
                securePost(this.hostUrl + "v1/authvia/line-integration/" + this.lineId)
                    .then((data) => {
                        this.isLoading = false;
                        if (data.success) {
                            this.authViaLineIntegration = data.authViaLineIntegration;
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                    });
            },
            getAuthViaLineIntegration() {
                this.isLoading = true;
                secureGet(this.hostUrl + "v1/authvia/line-integration/" + this.lineId)
                    .then((data) => {
                        this.isLoading = false;
                        if (data.success) {
                            this.authViaLineIntegration = data.authViaLineIntegration;
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                    });
            },
        },
    };
</script>