<template>
  <div class="mt-5" v-if="integrationRequiresDetails">
    <div class="control has-top-border">
      <h1 class="title mt-3">Integration Settings</h1>
    </div>
    <Loading v-if="isLoading" />

    <div v-if="hasNiceIntegration">
        <h4 class="subtitle is-4 mt-3 mb-2">Nice Settings</h4>
        <div v-if="routingMethod === 1" class="columns is-multiline mb-0">
            <div class="field column is-half mb-0">
                <label class="label">Point of Contact GUID</label>
                <div class="control">
                    <input class="input" type="text" placeholder="PointOfContactGUID..." v-model="pointOfContactId" @change="updateIntegrationDetails" />
                </div>
            </div>
            <div class="field column is-narrow is-align-self-flex-end mb-2">
                <label class="switch-label">
                    <input type="checkbox" class="switch" v-model="hideAttachmentURL" @change="updateIntegrationDetails" /> Hide Attachment URL
                </label>
            </div>
            <div class="field column is-narrow is-align-self-flex-end mb-2">
                <label class="switch-label">
                    <input type="checkbox" class="switch" v-model="startPollingOnChatCreation" @change="updateIntegrationDetails" /> Start Polling On Chat Creation
                </label>
            </div>
        </div>
        <div v-if="routingMethod === 2" class="mt-2">
            <h4 class="subtitle is-4 mt-3 mb-2">Webhooks</h4>
            <button v-if="!addingWebhook" class="button is-primary" @click="addingWebhook = !addingWebhook">
                Add
                Webhook
            </button>
            <div v-if="addingWebhook">
                <div class="columns mb-0 mt-2">
                    <div class="field column is-two-thirds mt-0">
                        <label class="label">Webhook URL</label>
                        <div class="control">
                            <input class="input" type="text" placeholder="Webhook URL..." v-model="newWebhook.webhookUrl">
                        </div>
                    </div>
                    <div class="field column">
                        <label class="label">Auth Type</label>
                        <div :class="authTypeDropdownActive ? 'dropdown is-active' : 'dropdown'">
                            <div class="dropdown-trigger">
                                <button class="button" aria-haspopup="true" aria-controls="dropdown-menu"
                                        @click="authTypeDropdownActive = !authTypeDropdownActive" @blur="delayCloseAuthTypeDropdown">
                                    <span>{{ authTypeDictionary[newWebhook.authType] }}</span>
                                    <span class="icon is-small">
                                        <font-awesome-icon :icon="['fa', 'angle-down']" />
                                    </span>
                                </button>
                            </div>
                            <div class="dropdown-menu" id="dropdown-menu" role="menu">
                                <div class="dropdown-content">
                                    <a v-for="(index, authTypeKey) in authTypeDictionary" :key="'authType' + index"
                                       class="dropdown-item"
                                       @click="setAuthType(authTypeKey)">
                                        {{ authTypeDictionary[authTypeKey] }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="field is-two-thirds column mt-0">
                        <label class="label">Auth Value</label>
                        <div class="control">
                            <input class="input" type="text" placeholder="Auth Value..." v-model="newWebhook.authValue">
                        </div>
                    </div>
                    <div class="field column mt-0 is-align-self-flex-end mb-3">
                        <button class="button is-primary" @click="addNewWebhook()">Add</button>
                    </div>
                </div>
            </div>
            <div class="columns mt-3" v-if="lineWebhooks && lineWebhooks.length > 0">
                <div class="column">
                    <div class="table-container">
                        <table class="table has-top-border has-bottom-border mt-0">
                            <thead>
                                <tr>
                                    <th>Webhook Url</th>
                                    <th>Auth Type</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(hook, index) in lineWebhooks" :key="index">
                                    <td>
                                        {{ hook.webhookUrl }}
                                    </td>
                                    <td>
                                        {{ authTypeDictionary[hook.authType] }}
                                    </td>
                                    <td class="pt-0">
                                        <button class="button is-white is-inverted is-small pt-0 mt-2" @click="deleteWebhook(index)">
                                            <font-awesome-icon :icon="['fa', 'trash-alt']" />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="routingMethod === 5" class="columns is-multiline mb-0">
            <div class="field column is-half mb-0">
                <label class="label">SpawnScript URL</label>
                <div class="control">
                    <input class="input" type="text" placeholder="SpawnScript URL..." v-model="spawnScriptUrl" @change="updateIntegrationDetails" />
                </div>
            </div>
        </div>
    </div>

    <div v-if="hasGenesysIntegration">
        <h4 class="subtitle is-4 mt-3 mb-2">Genesys Settings</h4>
        <div class="columns is-multiline mb-0">
            <div class="field column is-half mb-0">
                <label class="label">Chat Deployment GUID</label>
                <div class="control">
                    <input class="input" type="text" placeholder="ChatDeploymentGUID..." v-model="chatDeploymentId" @change="updateIntegrationDetails" />
                </div>
            </div>
            <div class="field column is-narrow is-align-self-flex-end mb-2">
                <label class="switch-label">
                    <input type="checkbox" class="switch" v-model="hideAttachmentUrlFromChat" @change="updateIntegrationDetails" /> Hide Attachment URL
                </label>
            </div>
        </div>
        <div class="columns is-multiline">
            <div class="field column is-half">
                <label class="label">Queue GUID</label>
                <div class="control">
                    <input class="input" type="text" placeholder="QueueGUID..." v-model="queueId" @change="updateIntegrationDetails">
                </div>
            </div>
        </div>
    </div>

    <div v-if="hasNiceDfoIntegration">
        <h4 class="subtitle is-4 mt-3 mb-2">Nice DFO BYOC Settings</h4>
        <div class="field column is-narrow pl-0">
            <label class="switch-label">
                <input type="checkbox" class="switch" v-model="hasChannelConnected" disabled /> Has Channel Connected
            </label>
        </div>
        <div class="field column is-narrow pl-0">
            <label class="switch-label">
                <input class="switch" type="checkbox" v-model="agentOutboundEnabled" @change="updateIntegrationDetails" /> Agent Outbound Enabled
            </label>
        </div>
    </div>

    <div v-if="hasRingCxIntegration">
      <h4 class="subtitle is-4 mt-3 mb-2">RingCX Settings</h4>
      <div class="columns is-multiline mb-0">
          <div class="field column">
              <label class="label">Real-time Endpoint URL </label>
              <div class="control">
                  <input class="input" type="text" placeholder="Real-time Endpoint URL..." v-model="realtimeEndpointUrl" @change="updateIntegrationDetails" />
              </div>
          </div>
      </div>
      <div class="columns is-multiline">
          <div class="field column is-half">
              <label class="label">Signature Secret</label>
              <div class="control">
                  <input class="input" type="text" placeholder="Signature Secret..." v-model="signatureSecret" @change="updateIntegrationDetails">
              </div>
          </div>
      </div>
      <div class="columns is-multiline">
          <div class="field column is-half">
              <label class="label">External Channel Id</label>
              <div class="control">
                  <input class="input" type="text" placeholder="Signature Secret..." v-model="externalChannelId" @change="updateIntegrationDetails">
              </div>
          </div>
      </div>
    </div>

    <div v-if="(hasNiceIntegration && routingMethod !== 5) || hasGenesysIntegration">
      <platform-settings v-model="platformOptions" @input="updateIntegrationDetails"></platform-settings>
    </div>
  </div>
</template>

<script>
import { secureGet } from '../secureFetch.js';
import Loading from "./Loading.vue"
import PlatformSettings from './PlatformSettings.vue';

    export default {
        name: 'LineIntegrationDetails',
        components: {
            Loading,
            PlatformSettings,
        },
        props: {
            integration: {
                type: Number,
                default: 0
            },
            lineId: {
                type: String,
                default: null,
            },
            publicLineId: {
                default: null
            },
            accountId: {
                type: String,
                default: null,
            },
            routingMethod: {
                type: Number,
                default: 1
            },
            platformAppOptions: {
              type: Number,
              default: 3,
            },
        },
        watch: {
            integration: {
              handler() {
                this.updateActiveIntegrations();
              },
              immediate: true,
            },
            lineWebhookChanges: {
                handler() {
                    this.emitWebhookChanges();
                },
                deep: true
            },
            platformAppOptions: {
              handler(newValue) {
                this.platformOptions = newValue;
              },
            },
        },
        computed: {
            hostUrl: function () {
                return this.$store.state.hostUrl;
            },
            integrationRequiresDetails: function () {
                return (parseInt(this.integration) === 3 && this.routingMethod !== 6) ||
                    parseInt(this.integration) === 4 ||
                    parseInt(this.integration) === 7 ||
                    parseInt(this.integration) === 8;
            }
        },
        data() {
            return {
                isLoading: false,
                integrationDictionary: { 1: 'Internal', 2: 'RingCentral', 3: 'Nice', 4: 'GenesysCCaaS', 5: 'GenesysUCaaS', 6: 'GenesysOpenMessaging' },
                hasNiceIntegration: false,
                hasNiceDfoIntegration: false,
                hasGenesysIntegration: false,
                hasRingCxIntegration: false,
                integrationDetails: {
                    niceIntegrationDetails: {},
                    genesysIntegrationDetails: {},
                    platformAppOptions: 3,
                },
                pointOfContactId: null,
                chatDeploymentId: null,
                queueId: null,
                hideAttachmentURL: false,
                hideAttachmentUrlFromChat: false,
                startPollingOnChatCreation: false,
                hasChannelConnected: false,
                agentOutboundEnabled: true,
                loadedNiceIntegration: false,
                loadedNiceDfoIntegration: false,
                loadedRingCxIntegration: false,
                lineWebhooks: [],
                lineWebhookChanges: {
                    webhooksToAdd: [],
                    webhooksToRemove: [],
                },
                newWebhook: { authType: 1 },
                authTypeDictionary: { 1: 'None', 2: 'Basic', 3: 'Bearer' },
                authTypeDropdownActive: false,
                addingWebhook: false,
                platformOptions: 3,
                spawnScriptUrl: '',
                realtimeEndpointUrl: '',
                signatureSecret: '',
                externalChannelId: '',
            }
        },
        methods: {
            delayCloseAuthTypeDropdown() {
                setTimeout(() => this.authTypeDropdownActive = false, 500);
            },
            setAuthType(authType) {
                this.newWebhook.authType = parseInt(authType);
                this.authTypeDropdownActive = false;
            },
            addNewWebhook() {
                if ((this.newWebhook?.authType && this.newWebhook?.webhookUrl?.trim() && this.newWebhook?.authValue?.trim()) ||
                    (this.newWebhook?.authType === 1 && this.newWebhook?.webhookUrl?.trim())) {
                    this.lineWebhookChanges.webhooksToAdd.push(this.newWebhook);
                    this.lineWebhooks.push(this.newWebhook);
                    this.newWebhook = { authType: 1 };
                    this.addingWebhook = false;
                }
            },
            deleteWebhook(index) {
                if (index >= 0 && this.lineWebhooks[index]) {
                    if (this.lineWebhooks[index].publicId > 0) {
                        this.lineWebhookChanges.webhooksToRemove.push(this.lineWebhooks[index].publicId);
                    }
                    else {
                        for (let i = 0; i < this.lineWebhookChanges.webhooksToAdd.length; i++) {
                            if (this.lineWebhookChanges.webhooksToAdd[i].authType == this.lineWebhooks[index].authType
                                && this.lineWebhookChanges.webhooksToAdd[i].webhookUrl == this.lineWebhooks[index].webhookUrl
                                && this.lineWebhookChanges.webhooksToAdd[i].authValue == this.lineWebhooks[index].authValue) {
                                this.lineWebhookChanges.webhooksToAdd.splice(i, 1);
                                break;
                            }
                        }
                    }
                    this.lineWebhooks.splice(index, 1);
                }
            },
            getWebhooks() {
                if (this.lineId) {
                    this.isLoading = true;
                    this.lineWebhookChanges = {
                        webhooksToAdd: [],
                        webhooksToRemove: [],
                    };
                    if (this.hasNiceIntegration) {
                        secureGet(this.hostUrl + "v1/nice/webhooks/" + this.publicLineId)
                            .then(data => {
                                this.isLoading = false;

                                if (data && data.success && data.message == null) {
                                    this.lineWebhooks = data.webhooks;
                                }
                            })
                    }
                    this.isLoading = false;
                }
            },
            updateActiveIntegrations() {
                this.hasNiceIntegration = false;
                this.hasGenesysIntegration = false;
                this.hasNiceDfoIntegration = false;
                this.hasRingCxIntegration = false;

                if (this.integration && parseInt(this.integration) > 0) {
                    let integration = parseInt(this.integration);
                    switch (integration) {
                        case 3:
                            this.hasNiceIntegration = true;
                            break;
                        case 4:
                            this.hasGenesysIntegration = true;
                            break;
                        case 7:
                            this.hasNiceDfoIntegration = true;
                            break;
                        case 8:
                            this.hasRingCxIntegration = true;
                            break;
                    }

                    this.$forceUpdate();
                }

                this.isLoading = false;
                this.getIntegrationDetails();
                this.getWebhooks();
            },
            getIntegrationDetails() {
                if (this.publicLineId) {
                    this.isLoading = true;
                    if (this.hasNiceIntegration) {
                        secureGet(this.hostUrl + "v1/nice/line-integration/" + this.publicLineId)
                            .then(data => {
                                this.isLoading = false;

                                if (data && data.success && data.externalCustomerLine != null) {
                                    if (!this.loadedNiceIntegration) {
                                        this.loadedNiceIntegration = true;
                                        this.$emit('loadedNiceIntegration', true);
                                    }

                                    this.pointOfContactId = data.externalCustomerLine.pointOfContactId;
                                    this.hideAttachmentURL = data.externalCustomerLine.hideAttachmentURL;
                                    this.startPollingOnChatCreation = data.externalCustomerLine.startPollingOnChatCreation;
                                    this.spawnScriptUrl = data.externalCustomerLine.spawnScriptUrl;
                                    this.updateIntegrationDetails();
                                }
                            });
                    }
                    else if (this.hasGenesysIntegration) {
                        secureGet(this.hostUrl + "v1/genesys/line-integration/" + this.publicLineId)
                            .then(data => {
                                this.isLoading = false;

                                if (data && data.success && data.externalCustomerLine != null) {
                                    if (!this.loadedGenesysIntegration) {
                                        this.loadedGenesysIntegration = true;
                                        this.$emit('loadedGenesysIntegration', true);
                                    }

                                    this.chatDeploymentId = data.externalCustomerLine.chatDeploymentId;
                                    this.queueId = data.externalCustomerLine.queueId;
                                    this.hideAttachmentUrlFromChat = data.externalCustomerLine.hideAttachmentUrlFromChat;
                                    this.updateIntegrationDetails();
                                }
                            });
                    }
                    else if (this.hasNiceDfoIntegration) {
                        secureGet(this.hostUrl + "v1/nicedfo/line-integration/" + this.publicLineId)
                            .then(data => {
                                this.isLoading = false;
                                
                                if (data && data.success) {
                                    if (data.channel != null) {
                                        this.hasChannelConnected = true;
                                    }

                                    if (data.externalCustomerLine != null) {
                                        this.agentOutboundEnabled = data.externalCustomerLine.agentOutboundEnabled;

                                        if (!this.loadedNiceDfoIntegration) {
                                            this.loadedNiceDfoIntegration = true;
                                            this.$emit('loadedNiceDfoIntegration', true);
                                        }
                                    }

                                    this.updateIntegrationDetails();
                                }
                            });
                    }
                    else if (this.hasRingCxIntegration) {
                        secureGet(this.hostUrl + "v1/ringcx/line-channel/" + this.publicLineId)
                            .then(data => {
                                this.isLoading = false;

                                if (data && data.success && data.ringCxLineChannel != null) {
                                    if (!this.loadedRingCxIntegration) {
                                        this.loadedRingCxIntegration = true;
                                        this.$emit('loadedRingCxIntegration', true);
                                    }

                                    this.realtimeEndpointUrl = data.ringCxLineChannel.realtimeEndpointUrl;
                                    this.signatureSecret = data.ringCxLineChannel.signatureSecret;
                                    this.externalChannelId = data.ringCxLineChannel.externalId;
                                    this.updateIntegrationDetails();
                                }
                            });
                    }

                    this.isLoading = false;
                }
                else if (this.hasNiceDfoIntegration) {
                  // call this regardless for new line, because we often don't make a change to agentOutboundEnabled,
                  // which would call this to let the parent know the integration details
                  this.updateIntegrationDetails();
                }
            },
            updateIntegrationDetails() {
                this.integrationDetails = {
                    niceIntegrationDetails: {
                        pointOfContactId: this.pointOfContactId,
                        hideAttachmentURL: this.hideAttachmentURL,
                        customerLineId: this.lineId,
                        startPollingOnChatCreation: this.startPollingOnChatCreation,
                        spawnScriptUrl: this.spawnScriptUrl
                    },
                    genesysIntegrationDetails: {
                        chatDeploymentId: this.chatDeploymentId,
                        queueId: this.queueId,
                        hideAttachmentUrlFromChat: this.hideAttachmentUrlFromChat,
                        customerLineId: this.lineId
                    },
                    niceDfoIntegrationDetails: {
                        agentOutboundEnabled: this.agentOutboundEnabled,
                    },
                    ringCxIntegrationDetails: {
                        realtimeEndpointUrl: this.realtimeEndpointUrl,
                        signatureSecret: this.signatureSecret,
                        externalId: this.externalChannelId,
                    },
                    platformAppOptions: this.platformOptions,
                };

                this.emitIntegrationDetails();
            },
            emitIntegrationDetails() {
                if (this.integration) {
                    this.$emit('integrationUpdated', this.integrationDetails);
                }
            },
            emitWebhookChanges() {
                if (this.lineWebhookChanges) {
                    this.$emit('lineWebhooksUpdated', this.lineWebhookChanges);
                }
            },
        },
    };

</script>
