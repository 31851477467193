<template>
    <div class="line">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/">Dashboard</router-link></li>
                <li><router-link to="/Lines">Lines</router-link></li>
                <li class="is-active"><a href="#" aria-current="page">{{ !line.lineName ? 'New Line' : line.lineName }}</a></li>
            </ul>
        </nav>

        <article v-if="errorMessage" class="message is-danger">
            <div class="message-header">
                <p>Save Error(s)</p>
                <button class="delete" aria-label="delete" @click="errorMessage = null"></button>
            </div>
            <div class="message-body">
                {{ errorMessage }}
            </div>
        </article>

        <article v-if="account.status !== 1" class="message is-warning">
            <div class="message-body">
                This line's account is not active and all associated functionality is disabled.
            </div>
        </article>

        <fieldset v-show="!isConfirmationView" :disabled="isLoading">
            <div class="field is-grouped">
                <div class="control is-expanded">
                    <h1 class="title">Line</h1>
                </div>
                <p class="control">
                    <button class="button is-primary" @click="save">
                        Save
                    </button>
                </p>
            </div>

            <div class="field">
                <label class="label">Base Line Id</label>
                <p class="has-text-grey-lighter mt-3"> {{ line.lineId ? line.lineId + ' (' + line.publicLineId + ')' : '-' }}</p>
            </div>

            <div class="field">
                <label class="label">Customer Line Id</label>
                <p class="has-text-grey-lighter mt-3"> {{ line.id ? line.id : '-' }}</p>
            </div>

            <label class="label">Account Id</label>
            <div class="field has-addons">
                <div v-if="!publicLineId" class="control is-expanded">
                    <input class="input" type="text" placeholder="Line account id..." v-model="line.accountId">
                </div>
                <p v-else class="has-text-grey-lighter mt-3"> {{ line.accountId }}</p>
                <div v-if="!publicLineId">
                    <AccountDropdown @accountSelected="accountSelected" />
                </div>
            </div>

            <div class="columns is-multiline mb-0">
                <div class="field column is-half">
                    <label class="label">Line Name</label>
                    <div class="control">
                        <input class="input" type="text" placeholder="Line name..." v-model="line.lineName">
                    </div>
                </div>

                <div class="field column is-half">
                    <label class="label">Phone Number</label>
                    <div v-if="!publicLineId" class="control">
                        <input class="input" type="text" placeholder="Phone number..." v-model="line.phoneNumber">
                    </div>
                    <p v-else class="has-text-grey-lighter mt-3"> {{ formatPhoneNumber(line.phoneNumber) }}</p>
                </div>
            </div>

            <div class="columns is-multiline mb-0">
                <div class="field column is-narrow">
                    <label class="label">Line Type</label>
                    <div :class="lineTypeDropdownActive ? 'dropdown is-active' : 'dropdown'">
                        <div class="dropdown-trigger">
                            <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="lineTypeDropdownActive = !lineTypeDropdownActive" @blur="delayCloseLineTypeDropdown">
                                <span>{{ lineTypeDictionary[line.lineType] }}</span>
                                <span class="icon is-small">
                                    <font-awesome-icon :icon="['fa', 'angle-down']" />
                                </span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content">
                                <a v-for="(index, lineTypeKey) in lineTypeDictionary" :key="'lineType' + index"
                                   :class="line.lineType == lineTypeKey ? 'dropdown-item is-active' : 'dropdown-item'" @click="setLineType(lineTypeKey)">
                                    {{ lineTypeDictionary[lineTypeKey] }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field column is-narrow">
                    <label class="label">Texting Service</label>
                    <div :class="serviceDropdownActive ? 'dropdown is-active' : 'dropdown'">
                        <div class="dropdown-trigger">
                            <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="serviceDropdownActive = !serviceDropdownActive" @blur="delayCloseServiceDropdown">
                                <span>{{ serviceDictionary[line.service] }}</span>
                                <span class="icon is-small">
                                    <font-awesome-icon :icon="['fa', 'angle-down']" />
                                </span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content">
                                <a v-for="(index, serviceTypeKey) in serviceDictionary" :key="'serviceType' + index"
                                   :class="line.service == serviceTypeKey ? 'dropdown-item is-active' : 'dropdown-item'" @click="setServiceType(serviceTypeKey)">
                                    {{ serviceDictionary[serviceTypeKey] }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field column is-narrow">
                    <label class="label">Integration</label>
                    <b-dropdown>
                        <template #trigger>
                            <b-button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                                <span>{{ line.integrations && line.integrations[0] ? integrationDictionary[line.integrations[0].integrationType] : integrationDictionary[1] }}</span>
                                <span class="icon is-small">
                                    <font-awesome-icon :icon="['fa', 'angle-down']" />
                                </span>
                            </b-button>
                        </template>
                        <b-dropdown-item v-for="(index, integrationTypeKey) in integrationDictionary"
                                         :key="'integrationType' + index"
                                         :value="integrationTypeKey"
                                         aria-role="listitem"
                                         @click="setIntegrationType(integrationTypeKey)">
                            {{integrationDictionary[integrationTypeKey]}}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>

                <div v-if="displayRoutingMethod" class="field column is-narrow">
                    <label class="label">Routing Method</label>
                    <div :class="routingMethodDropdownActive ? 'dropdown is-active' : 'dropdown'">
                        <div class="dropdown-trigger">
                            <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="routingMethodDropdownActive = !routingMethodDropdownActive" @blur="delayCloseRoutingMethodDropdown">
                                <span>{{ line.integrations && line.integrations[0] ? routingMethodDictionary[parseInt(line.integrations[0].routingMethod)] : routingMethodDictionary[1] }}</span>
                                <span class="icon is-small">
                                    <font-awesome-icon :icon="['fa', 'angle-down']" />
                                </span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content">
                                <a v-for="(value, key) in routingMethodDictionary" v-if="isValidRoutingMethod(key)" :key="'routingMethod' + value" :value="key"
                                   :class="line.integrations && line.integrations[0].routingMethod == key ? 'dropdown-item is-active' : 'dropdown-item'" @click="setRoutingMethod(key)">
                                    {{ routingMethodDictionary[key] }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field column is-narrow is-align-self-flex-end mb-5">
                    <label class="switch-label">
                        <input type="checkbox" class="switch" v-model="line.isBillingEnabled" /> Is Billing Enabled
                    </label>
                </div>
            </div>

            <!-- Don't show if texting service is bandwidth or sinch -->
            <div v-if="line.service != 2 && line.service != 3" class="columns is-multiline mb-0">
                <div class="field column is-half mb-0">
                    <label class="label">Service Key</label>
                    <div class="control">
                        <input class="input" type="text" placeholder="Service type..." v-model="line.serviceKey">
                    </div>
                </div>

                <div class="field column is-half mb-0">
                    <label class="label">Service User</label>
                    <div class="control">
                        <input class="input" type="text" placeholder="Service user..." v-model="line.serviceUser">
                    </div>
                </div>

                <div class="field column is-half mt-0">
                    <label class="label">Service Password</label>
                    <div class="control">
                        <input class="input" type="text" placeholder="Service password..." v-model="line.servicePassword">
                    </div>
                </div>
            </div>

            <!-- Don't show if texting service is not ring central. -->
            <div v-if="line.service == 4" class="columns is-multiline mb-0">
                <div class="field column is-half">
                    <label class="label">Service Subscription Id</label>
                    <div class="control">
                        <input disabled class="input" type="text" placeholder="Service subscription id..." v-model="line.serviceSubscriptionId">
                    </div>
                </div>

                <div class="field column is-half">
                    <label class="label">Service Outbound Subscription</label>
                    <div class="control">
                        <input disabled class="input" type="text" placeholder="Service outbound subscription id..." v-model="line.serviceOutboundSubscriptionId">
                    </div>
                </div>
            </div>

            <div class="field mt-2">
                <label class="switch-label">
                    <input type="checkbox" class="switch" v-model="line.isEnabled"> Is Enabled
                </label>
            </div>

            <article v-if="zipwhipErrorMessage" class="message is-danger">
                <div class="message-header">
                    <p>Webhook Error(s)</p>
                    <button class="delete" aria-label="delete" @click="zipwhipErrorMessage = null"></button>
                </div>
                <div class="message-body" style="white-space: pre-line;">
                    {{ zipwhipErrorMessage }}
                </div>
            </article>

            <div v-if="line.service == 1" class="block">
                <label class="label mt-5">Zipwhip Webhooks</label>
                <div class="field is-grouped">
                    <p class="control">
                        <button class="button is-primary" @click="generateZipwhipWebhooks" :disabled="zipwhipWebhooksGenerated">
                            Generate Webhooks
                        </button>
                    </p>
                    <p class="control">
                        <button class="button is-primary" @click="deleteZipwhipWebhooks" :disabled="!zipwhipWebhooksGenerated">
                            Delete Webhooks
                        </button>
                    </p>
                </div>

                <div v-if="zipwhipWebhooksGenerated" class="block">
                    <div class="field mb-0">
                        <label class="label">Status</label>
                        <div class="field is-grouped">
                            <div class="control is-expanded  mr-1">
                                <input class="input" type="text" placeholder="Status..." v-model="zipwhip.status.url" readonly>
                            </div>
                            <p class="control">
                                <button class="button is-primary" @click="copyToClipboard(zipwhip.status.url)">
                                    Copy
                                </button>
                            </p>
                        </div>
                    </div>

                    <div class="field mb-0 mt-2">
                        <label class="label">Receive</label>
                        <div class="field is-grouped">
                            <div class="control is-expanded mr-1">
                                <input class="input" type="text" placeholder="Receive..." v-model="zipwhip.receive.url" readonly>
                            </div>
                            <p class="control">
                                <button class="button is-primary" @click="copyToClipboard(zipwhip.receive.url)">
                                    Copy
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <LineIntegrationDetails :integration="integrationType"
                                    :publicLineId="publicLineId"
                                    :lineId="line.lineId"
                                    :accountId="line.accountId"
                                    :routingMethod="routingMethod"
                                    :platformAppOptions="platformAppOptions"
                                    @integrationUpdated="lineIntegrationChanged"
                                    @lineWebhooksUpdated="lineWebhooksChanged"
                                    @loadedNiceIntegration="loadedNiceIntegration"
                                    @loadedNiceDfoIntegration="loadedNiceDfoIntegration"
                                    @loadedGenesysIntegration="loadedGenesysIntegration"
                                    @loadedRingCxIntegration="loadedRingCxIntegration" />

            <ChatBotLineSettings ref="chatBotLineSettings"
                                 :accountId="line.accountId"
                                 :lineId="line.lineId"
                                 :isBotEnabledOnLoad="isBotEnabledOnLoad"
                                 @isBotEnabled="updateIsBotEnabled"
                                 @error="error" />

            <MeevoLineSettings v-if="account && account.meevoTenantId"
                               :publicCustomerLineId="line.publicLineId"
                               @error="error"
                               @meevoLocationId="updateMeevoLocationId"
                               @currentMeevoLocationId="updateCurrentMeevoLocationId" />

            <AuthViaLineSettings v-if="hasPaymentsProduct" :lineId="line.lineId"></AuthViaLineSettings>

            <ProductPermissions :productAccess="line.productAccess" @accessUpdated="setProductAccess" />

            <div class="field is-grouped is-grouped-right mt-5">
                <p class="control">
                    <button class="button is-primary" @click="save">
                        Save
                    </button>
                </p>
            </div>
        </fieldset>
        <div v-show="isConfirmationView">
            <div class="modal-card pt-6">
                <header class="modal-card-head">
                <p class="modal-card-title">Confirm Meevo Save Tenant Update</p>
                <button
                    class="delete is-medium"
                    @click="closeConfirmation()"
                    aria-label="close"
                ></button>
                </header>
                <section class="modal-card-body">
                <div class="content">
                    <p>
                    Taking this action will generate a new public api key and call
                    Meevo to update all lines on the account with a Meevo integration.
                    </p>
                    <p class="has-text-warning mt-2">Click "OK" to continue.</p>
                </div>
                </section>
                <footer class="modal-card-foot">
                <button
                    :class="
                    isLoading ? 'button is-primary is-loading' : 'button is-primary'
                    "
                    @click="confirmSave()"
                >
                    OK
                </button>
                <button class="button is-primary" @click="closeConfirmation()">
                    Cancel
                </button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
    import { secureGet, securePost, securePut, secureDelete, securePatch } from '../secureFetch.js';

    import Loading from '../components/Loading';
    import ProductPermissions from '../components/ProductPermissions';
    import AccountDropdown from '../components/AccountDropdown';
    import LineIntegrationDetails from "../components/LineIntegrationDetails.vue";
    import ChatBotLineSettings from "../components/ChatBotLineSettings.vue";
    import MeevoLineSettings from '../components/MeevoLineSettings.vue';
    import AuthViaLineSettings from '../components/AuthViaLineSettings.vue';
    import access from '../models/access';

    export default {
        name: 'CustomerLine',
        components: {
            AccountDropdown,
            Loading,
            ProductPermissions,
            LineIntegrationDetails,
            ChatBotLineSettings,
            MeevoLineSettings,
            AuthViaLineSettings,
        },
        computed: {
            hostUrl: function() {
                return this.$store.state.hostUrl;
            },
            zipwhipWebhooksGenerated: function() {
                return (this.zipwhip.status.url !== null || this.zipwhip.receive.url !== null)
            },
            currentEnvironment: function() {
                return this.$store.state.environment;
            },
            displayRoutingMethod: function() {
                return (this.integrationType === 2 || this.integrationType === 3 || this.integrationType === 4);
            },
            hasPaymentsProduct: function () {
                const paymentsProductId = access.productIds.Payments;
                const hasAccess = this.line?.productAccess?.some((x) => x.productId == paymentsProductId);
                return hasAccess;
            }
        },
        data() {
            return {
                isLoading: false,
                isLoadingPermissions: true,
                publicLineId: null,
                line: {
                    isEnabled: true,
                    accountId: null,
                },
                lineTypeDictionary: { 0: "Unknown", 1: 'Toll Free', 2: 'Domestic Long Code', 3: 'International Long Code', 4: 'Domestic Short Code', 5: 'International Short Code', 6: 'Alphanumeric' },
                lineTypeDropdownActive: false,
                lineWebhooksChanges: null,
                serviceDictionary: { 0: "Unknown", 1: 'Zipwhip', 2: 'Bandwidth', 3: "Sinch", 4: "RingCentral", 5: "Twilio" },
                serviceDropdownActive: false,
                integrationDictionary:{ 0: "Unknown", 1: 'Internal', 2: 'RingCentral MVP', 3: 'Nice Legacy Chat', 7: 'Nice DFO BYOC', 4: 'GenesysCCaaS', 5: 'GenesysUCaaS', 9: 'GenesysOpenMessaging', 6: 'None', 8: 'RingCX' },
                integrationType: 1,
                platformAppOptions: 3,
                integration: {},
                integrationDetails: {},
                routingMethod: 1,
                routingMethodDictionary: { 0: 'Unknown', 1: 'Chat', 2: 'Nice DFO (Legacy)', 3: 'RingCentral SMS', 4: 'Chatbot', 5: 'SpawnScript', 6: 'Do Nothing' },
                routingMethodDropdownActive: false,
                niceIntegrationLoaded: false,
                niceDfoIntegrationLoaded: false,
                genesysIntegrationLoaded: false,
                ringCxIntegrationLoaded: false,
                products: null,
                errorMessage: null,
                lineErrorMessage: null,
                integrationErrorMessage: null,
                defaultErrorMessage: "Something didn't work.",
                zipwhip: {
                    status: {
                        webhookId: null,
                        url: null,
                    },
                    receive: {
                        webhookId: null,
                        url: null,
                    },
                },
                zipwhipErrorMessage: null,
                isBotEnabled: false,
                isBotEnabledOnLoad: false,
                account: {
                    status: 1,
                },
                meevoLocationId: null,
                currentMeevoLocationId: null,
                isConfirmationView: false,
            }
        },
         mounted() {
            this.publicLineId = this.$route.params.publicLineId;
            this.getLine();
        },
        methods: {
            error(error) {
                this.errorMessage = error;
            },
            getLine() {
                if (this.publicLineId) {
                    this.isLoading = true;
                    this.isLoadingPermissions = true;

                    secureGet(this.hostUrl + "v1/line/" + this.publicLineId)
                        .then(data => {
                            if (data && data.success) {
                                this.line = data.customerLine;
                                if (this.line.isBotEnabled) {
                                    this.isBotEnabled = true;
                                    this.isBotEnabledOnLoad = true;
                                }
                                this.integrationType = this.line.integrations[0] ? parseInt(this.line.integrations[0].integrationType) : 0;
                                this.routingMethod = this.line.integrations[0] ? parseInt(this.line.integrations[0].routingMethod) : 1;
                                if (this.line.integrations[0] && this.line.integrations[0].platformAppOptions != null) {
                                  this.platformAppOptions = parseInt(this.line.integrations[0].platformAppOptions);
                                } else {
                                  // default, if null both text and attachments are allowed
                                  this.platformAppOptions = 3;
                                }

                                if (data.customerLine.service == 1) {
                                    this.getZipwhipWebhooks(data.customerLine.serviceKey);
                                }

                                this.getAccount();
                            }

                            this.isLoading = false;
                        });
                }
                else {
                    this.line.lineType = 0;
                    this.line.integrations = [{ integrationType: 0, routingMethod: 0 }];
                    this.line.service = 0;
                }
            },
            delayCloseLineTypeDropdown() {
                setTimeout(() => this.lineTypeDropdownActive = false, 500);
            },
            delayCloseIntegrationDropdown() {
                setTimeout(() => this.integrationDropdownActive = false, 500);
            },
            delayCloseRoutingMethodDropdown() {
                setTimeout(() => this.routingMethodDropdownActive = false, 500);
            },
            delayCloseServiceDropdown() {
                setTimeout(() => this.serviceDropdownActive = false, 500);
            },
            updateIsBotEnabled(value) {
                this.isBotEnabled = value;
            },
            updateMeevoLocationId(value) {
                this.meevoLocationId = value;
            },
            updateCurrentMeevoLocationId(value) {
                this.currentMeevoLocationId = value;
            },
            closeConfirmation() {
                this.isConfirmationView = false;
            },
            showConfirmation() {
                this.isConfirmationView = true;
            },
            setLineType(lineType) {
                this.line.lineType = parseInt(lineType);
                this.lineTypeDropdownActive = false;
            },
            setIntegrationType(integrationType) {
                this.integrationType = parseInt(integrationType);
                this.routingMethod = 1;
                this.setLineIntegrations();
            },
            setServiceType(serviceType) {
                this.line.service = parseInt(serviceType);
                this.serviceDropdownActive = false;
            },
            setRoutingMethod(routingMethod) {
                this.routingMethod = parseInt(routingMethod);
                this.routingMethodDropdownActive = false;
                this.setLineIntegrations();
            },
            setLineIntegrations() {
                this.line.integrations = [{ 
                  integrationType: this.integrationType,
                  routingMethod: this.routingMethod,
                  customerLineId: this.line.lineId,
                  platformAppOptions: this.platformAppOptions,
                }];
            },
            isValidRoutingMethod(routingMethodKey) {
                const key = parseInt(routingMethodKey);
                // RingCentral routing methods
                if (this.integrationType === 2) {
                    if (key === 1 || key === 3) return true;
                }
                // Nice routing methods
                if (this.integrationType === 3) {
                    if (key === 1 || key === 2 || key == 5 || key == 6) return true;
                }
                // Genesys CCaaS routing methods
                if (this.integrationType === 4) {
                    if (key === 1) return true;
                }
                return false;
            },
            save() {
                this.errorMessage = null;

                if (this.meevoLocationId && this.currentMeevoLocationId != this.meevoLocationId) {
                    this.showConfirmation();
                }
                else {
                    this.confirmSave();
                }
            },
            async confirmSave() {
                // We only want to update the line's isBotEnabled field if the chat bot is valid
                if (this.$refs.chatBotLineSettings.save()) {
                    this.line.isBotEnabled = this.isBotEnabled;
                }

                if (this.validateLine()) {
                    if (this.publicLineId) {
                        this.updateLine();
                    }
                    else {
                        await this.createLine();
                    }

                    if (this.currentMeevoLocationId != this.meevoLocationId) {
                        if (this.meevoLocationId) {
                            this.upsertMeevoLineIntegration();
                        }
                        else {
                            this.deleteMeevoLineIntegration();
                        }
                    }

                    if (this.integrationType === 3) {
                        if (this.routingMethod !== 2) {
                            if (this.niceIntegrationLoaded) {
                                this.updateLineIntegration();
                            }
                            else {
                                this.createLineIntegration();
                            }
                        }

                        if (this.lineWebhooksChanges) {
                            this.deleteLineWebhooks();
                            this.createLineWebhooks();
                        }
                    }
                    else if (this.integrationType === 4) {
                        if (this.genesysIntegrationLoaded) {
                            this.updateLineIntegration();
                        }
                        else {
                            this.createLineIntegration();
                        }
                    }
                    else if (this.integrationType === 7) {
                        if (this.niceDfoIntegrationLoaded) {
                            this.updateLineIntegration();
                        }
                        else {
                            this.createLineIntegration();
                        }
                    }
                    else if (this.integrationType === 8) {
                        if (this.ringCxIntegrationLoaded) {
                            this.updateLineIntegration();
                        }
                        else {
                            this.createLineIntegration();
                        }
                    }
                    else if (this.integrationType === 9) {
                        this.createLineIntegration();
                        // no integration details to update
                    }
                }

                this.closeConfirmation();
            },
            updateLine() {
                this.isLoading = true;
                this.lineErrorMessage = null;

                securePut(this.hostUrl + "v1/line/" + this.publicLineId, this.line)
                    .then((data) => {
                        this.isLoading = false;
                
                        if (data.success) {
                            this.line = data.customerLine;
                            this.integrationType = this.line.integrations[0] ? parseInt(this.line.integrations[0].integrationType) : 0;
                            this.routingMethod = this.line.integrations[0] ? this.line.integrations[0].routingMethod : 0;
                        } else {
                            this.lineErrorMessage = data.message;
                
                            if (!this.lineErrorMessage) {
                                this.lineErrorMessage = this.defaultErrorMessage;
                            }
                
                            this.setErrorMessage();
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            async createLine() {
                if (!this.line.accountId) {
                    this.lineErrorMessage = "Account id is required.";
                }
                else if (!this.line.lineName) {
                    this.lineErrorMessage = "Line name is required.";
                }
                else if (!this.line.phoneNumber) {
                    this.lineErrorMessage = "Phone number is required.";
                }
                else {
                    this.isLoading = true;
                    this.lineErrorMessage = null;

                    await securePost(this.hostUrl + "v1/line/create-line", this.line)
                        .then((data) => {
                            this.isLoading = false;
                    
                            if (data.success) {
                                this.line = data.customerLine;
                                this.publicLineId = data.customerLine.publicLineId;
                                this.integrationType = this.line.integrations[0] ? parseInt(this.line.integrations[0].integrationType) : 0;
                                this.routingMethod = this.line.integrations[0] ? this.line.integrations[0].routingMethod : 0;
                            } else {
                                this.lineErrorMessage = data.message;
                    
                                if (!this.lineErrorMessage) {
                                    this.lineErrorMessage = this.defaultErrorMessage;
                                }
                    
                                this.setErrorMessage();
                            }
                        })
                        .catch((e) => {
                            this.isLoading = false;
                            this.errorMessage = this.defaultErrorMessage;
                            console.error(e);
                        });
                }
            },
            updateLineIntegration() {
                this.isLoading = true;
                this.integrationErrorMessage = null;

                let endpointUrl;
                let updateIntegrationDetails;

                if (this.integrationType === 3) {
                    endpointUrl = "v1/nice/line-integration";
                    updateIntegrationDetails = this.integrationDetails.niceIntegrationDetails;
                }
                else if (this.integrationType === 4) {
                    endpointUrl = "v1/genesys/line-integration";
                    updateIntegrationDetails = this.integrationDetails.genesysIntegrationDetails;
                }
                else if (this.integrationType === 7) {
                    endpointUrl = "v1/nicedfo/line-integration";
                    updateIntegrationDetails = this.integrationDetails.niceDfoIntegrationDetails;
                }
                else if (this.integrationType === 8) {
                    endpointUrl = `v1/ringcx/line-channel/${this.publicLineId}`;
                    updateIntegrationDetails = this.integrationDetails.ringCxIntegrationDetails;
                }

                if (updateIntegrationDetails != null) {
                    updateIntegrationDetails.customerLineId = this.line.lineId;

                    if (this.integrationType !== 7) {
                        updateIntegrationDetails.internalAccountId = this.line.accountId;
                    }

                    securePatch(this.hostUrl + endpointUrl, updateIntegrationDetails)
                        .then(data => {
                            this.isLoading = false;

                            if (data.success) {
                                if (this.integrationType === 3) {
                                    this.integrationDetails.niceIntegrationDetails = data.externalCustomerLine;
                                }
                                else if (this.integrationType === 4) {
                                    this.integrationDetails.genesysIntegrationDetails = data.externalCustomerLine;
                                }
                                else if (this.integrationType === 8) {
                                    this.integrationDetails.ringCxIntegrationDetails = data.ringCxLineChannel;
                                }
                            }
                            else {
                                this.integrationErrorMessage = data.message;

                                if (!this.integrationErrorMessage) {
                                    this.integrationErrorMessage = this.defaultErrorMessage;
                                }

                                this.setErrorMessage();
                            }
                        })
                        .catch((e) => {
                            this.isLoading = false;
                            this.errorMessage = this.defaultErrorMessage;
                            console.error(e);
                        });
                }
            },
            createLineIntegration() {
                this.isLoading = true;
                this.integrationErrorMessage = null;

                let endpointUrl;
                let createIntegrationDetails;

                if (this.integrationType === 3) {
                    endpointUrl = "v1/nice/line-integration";
                    createIntegrationDetails = this.integrationDetails.niceIntegrationDetails;
                }
                else if (this.integrationType === 4) {
                    endpointUrl = "v1/genesys/line-integration";
                    createIntegrationDetails = this.integrationDetails.genesysIntegrationDetails;
                }
                else if (this.integrationType === 7) {
                    endpointUrl = "v1/nicedfo/line-integration";
                    createIntegrationDetails = this.integrationDetails.niceDfoIntegrationDetails;
                }
                else if (this.integrationType === 8) {
                    endpointUrl = `v1/ringcx/line-channel/${this.publicLineId}`;
                    createIntegrationDetails = this.integrationDetails.ringCxIntegrationDetails;
                }
                else if (this.integrationType === 9) {
                    endpointUrl = "v1/genesysmessaging/line-integration";
                    createIntegrationDetails = {};
                }

                if (createIntegrationDetails != null) {
                    createIntegrationDetails.customerLineId = this.line.lineId;

                    if (this.integrationType !== 7) {
                        createIntegrationDetails.internalAccountId = this.line.accountId;
                    }

                    securePost(this.hostUrl + endpointUrl, createIntegrationDetails)
                        .then(data => {
                            this.isLoading = false;

                            if (data.success) {
                                if (this.integrationType === 3) {
                                    this.integrationDetails.niceIntegrationDetails = data.externalCustomerLine;
                                }
                                else if (this.integrationType === 4) {
                                    this.integrationDetails.genesysIntegrationDetails = data.externalCustomerLine;
                                }
                                else if (this.integrationType === 7) {
                                    this.integrationDetails.niceDfoIntegrationDetails = {
                                        hasChannelConnected: true,
                                        agentOutboundEnabled: data.externalCustomerLine.agentOutboundEnabled
                                    };
                                    this.niceDfoIntegrationLoaded = true;
                                }
                                else if (this.integrationType === 8) {
                                    this.integrationDetails.ringCxIntegrationDetails = data.ringCxLineChannel;
                                }
                            }
                            else {
                                this.integrationErrorMessage = data.message;

                                if (!this.integrationErrorMessage) {
                                    this.integrationErrorMessage = this.defaultErrorMessage;
                                }

                                this.setErrorMessage();
                            }
                        })
                        .catch((e) => {
                            this.isLoading = false;
                            this.errorMessage = this.defaultErrorMessage;
                            console.error(e);
                        });
                }
            },
            deleteLineWebhooks() {
                this.isLoading = true;
                if (this.lineWebhooksChanges.webhooksToRemove) {
                    this.lineWebhooksChanges.webhooksToRemove.forEach(webhookPublicId => {
                        secureDelete(this.hostUrl + "v1/nice/webhook/" + webhookPublicId);
                    });
                }
                this.lineWebhooksChanges.webhooksToRemove = [];
                this.isLoading = false;
            },
            createLineWebhooks() {
                this.isLoading = true;
                if (this.lineWebhooksChanges.webhooksToAdd) {
                    this.lineWebhooksChanges.webhooksToAdd.forEach(newWebhook => {
                        let body = {
                            accountId: this.line.accountId,
                            publicLineId: this.publicLineId,
                            webhookUrl: newWebhook.webhookUrl,
                            webhookEventType: 1,                    // Message
                            authType: newWebhook.authType,
                            authValue: newWebhook.authValue,
                            apiVersion: this.routingMethod == 2 ? 2 : 1
                        }
                        securePost(this.hostUrl + "v1/nice/webhook", body);
                    });
                }
                this.lineWebhooksChanges.webhooksToAdd = [];
                this.isLoading = false;
            },
            setErrorMessage() {
                this.errorMessage = null;

                if (this.line.integrations[0] === 3) {
                    if (this.lineErrorMessage && this.integrationErrorMessage) {
                        this.errorMessage = "Line: " + this.lineErrorMessage + " Integration: " + this.integrationErrorMessage;
                    }
                }
                else {
                    this.errorMessage = this.lineErrorMessage;
                }
            },
            formatPhoneNumber(numberString) {
                var phoneNumber = numberString;

                var cleaned = ('' + numberString).replace(/\D/g, '');
                
                var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
                
                if (match) {
                    var intlCode = (match[1] ? '+1 ' : '')
                    phoneNumber = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
                }
                
                return phoneNumber;
            },
            setProductAccess(productAccess) {
                this.line.productAccess = productAccess;
            },
            validateLine() {
                var errorMessages = [];

                if (!this.line.accountId || this.line.accountId.trim() === '') {
                    errorMessages.push('Acount Id is required');
                }

                if (!this.line.lineName || this.line.lineName.trim() === '') {
                    errorMessages.push('Line Name is required');
                }

                if (!this.line.phoneNumber || this.line.phoneNumber.trim() === '') {
                    errorMessages.push('Phone Number is required (E.164)');
                }

                if (!this.line.lineType || this.line.lineType === 0) {
                    errorMessages.push('Line Type is required');
                }

                if (!this.line.integrations || this.line.integrations.length <= 0 || this.line.integrations.some((item) => item.integrationType === 0)) {
                    errorMessages.push('Integration type is required');
                }

                if (!this.line.integrations || this.line.integrations.length <= 0 || this.line.integrations.some((item) => item.routingMethod === 0)) {
                    errorMessages.push('Routing Method is required');
                }

                if (!this.line.service || this.line.service === 0) {
                    errorMessages.push('Texting Service is required');
                }

                // Service options not required for Sinch or Bandwidth
                if ((this.line.service != 2 && this.line.service != 3) &&
                    (!this.line.serviceKey || this.line.serviceKey.trim() === '') &&
                    (!this.line.serviceUser || this.line.serviceUser.trim() === '') &&
                    (!this.line.servicePassword || this.line.servicePassword.trim() === '')) {
                    errorMessages.push('Service Key, Service User, or Service Password is required');
                }

                if (this.meevoLocationId && (isNaN(this.meevoLocationId) || parseInt(this.meevoLocationId) < 1)) {
                    errorMessages.push('Invalid Meevo location id provided.');
                }

                if (this.integrationType === 3 && (this.routingMethod === 1 || this.routingMethod === 5)) {
                    if (!this.integrationDetails) {
                        errorMessages.push('Nice Integration Details are required')
                    }
                    else {
                        if (!this.integrationDetails.niceIntegrationDetails) {
                            errorMessages.push('Nice Integration Details are required')
                        }
                        else {
                            if (this.routingMethod === 1 && (!this.integrationDetails.niceIntegrationDetails.pointOfContactId || this.integrationDetails.niceIntegrationDetails.pointOfContactId === '')) {
                                errorMessages.push('Nice Integration Point of Contact Id is required')
                            }
                            if (this.routingMethod === 5 && (!this.integrationDetails.niceIntegrationDetails.spawnScriptUrl || this.integrationDetails.niceIntegrationDetails.spawnScriptUrl === '')) {
                                errorMessages.push('Nice Integration Spawn Script Url is required')
                            }
                        }
                    }
                } else if (this.integrationType === 4) {
                    if (!this.integrationDetails) {
                        errorMessages.push('Genesys Integration Details are required')
                    }
                    else {
                        if (!this.integrationDetails.genesysIntegrationDetails) {
                            errorMessages.push('Genesys Integration Details are required')
                        }
                        else {
                            if (!this.integrationDetails.genesysIntegrationDetails.chatDeploymentId || this.integrationDetails.genesysIntegrationDetails.chatDeploymentId === '') {
                                errorMessages.push('Genesys Integration Chat Deployment Id is required')
                            }
                            if (!this.integrationDetails.genesysIntegrationDetails.queueId || this.integrationDetails.genesysIntegrationDetails.queueId === '') {
                                errorMessages.push('Genesys Integration Queue Id is required')
                            }
                        }
                    }
                }
                else if (this.integrationType === 8) {
                    if (!this.integrationDetails) {
                        errorMessages.push('RingCX Integration details are required')
                    }
                    else {
                        if (!this.integrationDetails.ringCxIntegrationDetails) {
                            errorMessages.push('RingCX Integration details are required')
                        }
                        else {
                            if (!this.integrationDetails.ringCxIntegrationDetails.realtimeEndpointUrl || this.integrationDetails.ringCxIntegrationDetails.realtimeEndpointUrl === '') {
                                errorMessages.push('RingCX Integration Real-time Endpoint URL is required')
                            }
                            if (!this.integrationDetails.ringCxIntegrationDetails.signatureSecret || this.integrationDetails.ringCxIntegrationDetails.signatureSecret === '') {
                                errorMessages.push('RingCX Integration Signature Secret is required')
                            }
                            if (!this.integrationDetails.ringCxIntegrationDetails.externalId || this.integrationDetails.ringCxIntegrationDetails.externalId === '') {
                                errorMessages.push('RingCX Integration External Channel Id is required')
                            }
                        }
                    }
                }

                if (errorMessages.length > 0) {
                    this.errorMessage = errorMessages.join('; ');
                    document.getElementById("column-right").scrollTo({top: 0, behavior: "smooth"});
                }

                return errorMessages.length === 0;
            },
            async getZipwhipWebhooks(serviceKey) {
                const url = "https://api.zipwhip.com/webhook/list";
                const params = "session=" + serviceKey;
                const urlParams = new URLSearchParams(params);

                if (!serviceKey) {
                    this.zipwhipErrorMessage = "Service Key needed to retreive webhooks";
                    return
                }
    
                await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: urlParams
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            for (var webhook of data.response) {
                                if (webhook.event == "progress" && (webhook.url.includes('foundation.textel.net') || webhook.url.includes('foundation-test.textel.net'))) {
                                    this.zipwhip.status.url = webhook.url;
                                    this.zipwhip.status.webhookId = webhook.webhookId;
                                    }
                                else if (webhook.event == "receive" && (webhook.url.includes('foundation.textel.net') || webhook.url.includes('foundation-test.textel.net'))) {
                                    this.zipwhip.receive.url = webhook.url;
                                    this.zipwhip.receive.webhookId = webhook.webhookId;
                                    }
                                }
                        } else {
                            ErrorMessages.push(data.errorDesc || this.defaultErrorMessage);
                        }
                        })
                        .catch((e) => {
                            this.zipwhipErrorMessage = this.defaultErrorMessage;
                            console.error(e);
                        });
            },
            async generateZipwhipWebhooks() {
                if (!this.line.serviceKey) {
                    this.zipwhipErrorMessage = "Service Key needed to generate webhooks, make sure to save before generating webhooks";
                    return
                }

                if (!this.line.zipwhipReceiveCallbackAuthKey || !this.line.zipwhipStatusCallbackAuthKey) {
                    this.zipwhipErrorMessage = "Auth Keys needed to generate webhooks, make sure to save before generating webhooks";
                    return
                }

                var ErrorMessages = [];
                var callbackUrl;
                const url = "https://api.zipwhip.com/webhook/add";
                const serviceKey = this.line.serviceKey;
                const receiveAuthKey = this.line.zipwhipReceiveCallbackAuthKey;
                const statusAuthKey = this.line.zipwhipStatusCallbackAuthKey;

                if (this.currentEnvironment == 2) {
                    callbackUrl = "https://foundation.textel.net/api/conversation/v1/carriercallbacks/zipwhip-"
                }
                else {
                    callbackUrl = "https://foundation-test.textel.net/api/conversation/v1/carriercallbacks/zipwhip-"
                }
                
                var receiveUrlParams = new URLSearchParams();
                receiveUrlParams.append("session", serviceKey);
                receiveUrlParams.append("event", "receive");
                receiveUrlParams.append("url", callbackUrl + "receive?authKey=" + receiveAuthKey);

                var statusUrlParams = new URLSearchParams();
                statusUrlParams.append("session", serviceKey);
                statusUrlParams.append("event", "progress");
                statusUrlParams.append("url", callbackUrl + "status?authKey=" + statusAuthKey);

                await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: receiveUrlParams
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            for (var webhook of data.response) {
                                if (webhook.event == "receive" && webhook.url.includes(callbackUrl)) {
                                    this.zipwhip.receive.url = webhook.url;
                                    this.zipwhip.receive.webhookId = webhook.webhookId;
                                    }
                                }
                        } else {
                            ErrorMessages.push(data.errorDesc || this.defaultErrorMessage);
                        }
                        })
                        .catch((e) => {
                            ErrorMessages.push(this.defaultErrorMessage);
                            console.error(e);
                        });
                
                await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: statusUrlParams
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            for (var webhook of data.response) {
                                if (webhook.event == "progress" && webhook.url.includes(callbackUrl)) {
                                    this.zipwhip.status.url = webhook.url;
                                    this.zipwhip.status.webhookId = webhook.webhookId;
                                    }
                                }
                        } else {
                            ErrorMessages.push(data.errorDesc || this.defaultErrorMessage);
                        }
                        })
                        .catch((e) => {
                            ErrorMessages.push(this.defaultErrorMessage);
                            console.error(e);
                        });

                if (ErrorMessages.length > 0) {
                    this.zipwhipErrorMessage = ErrorMessages.join(';\r\n');
                }
            },
            async deleteZipwhipWebhooks() {
                if (!this.line.serviceKey) {
                    this.zipwhipErrorMessage = "Service Key needed to generate webhooks, make sure to save before generating webhooks";
                    return
                }

                var ErrorMessages = [];
                const url = "https://api.zipwhip.com/webhook/delete";
                const serviceKey = this.line.serviceKey;
                
                var receiveUrlParams = new URLSearchParams();
                receiveUrlParams.append("session", serviceKey);
                receiveUrlParams.append("webhookId", this.zipwhip.receive.webhookId);

                var statusUrlParams = new URLSearchParams();
                statusUrlParams.append("session", serviceKey);
                statusUrlParams.append("webhookId", this.zipwhip.status.webhookId);

                await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: receiveUrlParams
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            this.zipwhip.receive.url = null;
                            this.zipwhip.receive.webhookId = null;

                            for (var webhook of data.response) {
                                if (webhook.event == "receive") {
                                    if (webhook.url.includes('foundation.textel.net') || webhook.url.includes('foundation-test.textel.net')) {
                                        ErrorMessages.push("WARNING: An additional receive webhook pointing to foundation exists for this ServiceKey. Refresh page to see it.");
                                    }
                                    else {
		                            ErrorMessages.push("NOTE: This Zipwhip ServiceKey has additional receive webhooks associated with it outside of foundation");
                                    }
                                }
                            }
                        } else {
                            ErrorMessages.push(data.errorDesc || this.defaultErrorMessage);
                        }
                        })
                        .catch((e) => {
                            ErrorMessages.push(this.defaultErrorMessage);
                            console.error(e);
                        });
                
                await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: statusUrlParams
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            this.zipwhip.status.url = null;
                            this.zipwhip.status.webhookId = null;
                            for (var webhook of data.response) {
                                if (webhook.event == "progress") {
		                            if (webhook.url.includes('foundation.textel.net') || webhook.url.includes('foundation-test.textel.net')) {
                                        ErrorMessages.push("WARNING: An additional status webhook pointing to foundation exists for this ServiceKey. Refresh page to see it.");
                                    }
                                    else {
                                        ErrorMessages.push("NOTE: This Zipwhip ServiceKey has additional status webhooks associated with it outside of foundation");
                                    }
                                }
                            }
                        } else {
                            ErrorMessages.push(data.errorDesc || this.defaultErrorMessage);
                        }
                        })
                        .catch((e) => {
                            ErrorMessages.push(this.defaultErrorMessage);
                            console.error(e);
                        });

                if (ErrorMessages.length > 0) {
                    this.zipwhipErrorMessage = ErrorMessages.join(';\r\n');
                }
            },
            copyToClipboard(text) {
                try {
                    navigator.clipboard.writeText(text);
                } catch (error) {
                    alert('Could not copy');
                }
            },
            accountSelected(accountId) {
                this.line.accountId = accountId;
            },
            lineIntegrationChanged(integrationDetails) {
                this.platformAppOptions = integrationDetails.platformAppOptions;
                this.integrationDetails = integrationDetails;
                this.setLineIntegrations();
            },
            lineWebhooksChanged(lineWebhookChanges) {
                this.lineWebhooksChanges = lineWebhookChanges;
            },
            loadedNiceIntegration(niceLoaded) {
                this.niceIntegrationLoaded = niceLoaded;
            },
            loadedNiceDfoIntegration(loaded) {
                this.niceDfoIntegrationLoaded = loaded;
            },
            loadedGenesysIntegration(genesysLoaded) {
                this.genesysIntegrationLoaded = genesysLoaded;
            },
            loadedRingCxIntegration(loaded) {
                this.ringCxIntegrationLoaded = loaded;
            },
            getAccount() {
                this.isLoading = true;

                secureGet(this.hostUrl + "v1/account/" + this.line.accountId)
                    .then(data => {
                        if (data && data.success) {
                            this.account = data.account;
                        }
                        this.isLoading = false;
                    });
            },
            upsertMeevoLineIntegration() {
                this.isLoading = true;

                const request = {
                    publicAccountId: this.account.publicId,
                    publicCustomerLineId: this.line.publicLineId,
                    locationId: this.meevoLocationId,
                };

                securePut(this.hostUrl + "v1/meevo/line-integration/", request)
                    .then((data) => {
                    this.isLoading = false;

                    if (!data.success) {
                        this.errorMessage = data.message;

                        if (!this.errorMessage) {
                        this.errorMessage = this.defaultErrorMessage;
                        }
                    }
                    })
                    .catch((e) => {
                    this.isLoading = false;
                    this.errorMessage = this.defaultErrorMessage;
                    console.error(e);
                    });
            },
            deleteMeevoLineIntegration() {
                this.loading = true;

                 secureDelete(this.hostUrl + "v1/meevo/line-integration?publicCustomerLineId=" + this.line.publicLineId)        
                     .then((data) => {
                         this.isLoading = false;

                        if (!data.success) {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                     })
                     .catch((e) => {
                         this.isLoading = false;

                         this.errorMessage = this.defaultErrorMessage;
                         console.error(e);
                 });
            },
        }
    };
</script>

<style scoped>
</style>