<template>
    <div>
        <div class="control has-top-border">
          <h1 class="title mt-3">Bot Settings</h1>
        </div>
        <div class="columns is-multiline mb-0 mt-3">
            <div class="field column is-narrow" :class="isEnabled ? 'pt-6' : 'pt-2'">
                <label class="switch-label">
                    <input class="switch" type="checkbox" v-model="isEnabled" /> Is Bot Enabled
                </label>
            </div>
            <div v-if="isEnabled" class="columns is-multiline field column is-narrow">
                <div class="field column is-narrow">
                    <label class="label">Bot Type</label>
                    <div :class="chatBotTypeDropdownActive ? 'dropdown is-active' : 'dropdown'">
                        <div class="dropdown-trigger">
                            <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="chatBotTypeDropdownActive = !chatBotTypeDropdownActive" @blur="delayChatBotTypeDropdown">
                                <span>{{ chatBotType }}</span>
                                <span class="icon is-small">
                                    <font-awesome-icon :icon="['fa', 'angle-down']" />
                                </span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content">
                                <a role="button"
                                   v-for="(type, index) in chatBotTypeOptions"
                                   :key="index"
                                   :value="type"
                                   :class="chatBotType == type ? 'dropdown-item is-active' : 'dropdown-item'"
                                   @click="setChatBotType(type)">
                                    {{type}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="chatBotType == 'Catch-All'" class="field column is-narrow">
                    <label class="label">Capacity Guided Conversation</label>
                    <div :class="guidedConversationDropdownActive ? 'dropdown is-active' : 'dropdown'">
                        <div class="dropdown-trigger">
                            <input type="search"
                                   class="button searchable-dropdown"
                                   aria-haspopup="true"
                                   aria-controls="dropdown-menu"
                                   @click="guidedConversationDropdownActive = !guidedConversationDropdownActive"
                                   @blur="delayGuidedConversationDropdown"
                                   v-model="search"
                                   placeholder="Select"/>
                        </div>
                        <div v-if="guidedConversationsLoaded" class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content height-cap">
                                <a role="button"
                                   v-for="(guidedConversationOption, index) in searchedGuidedConversations"
                                   :key="index"
                                   :value="guidedConversationOption"
                                   :class="guidedConversation.id == guidedConversationOption.id ? 'dropdown-item is-active' : 'dropdown-item'"
                                   @click="setGuidedConversation(guidedConversationOption)">
                                    {{ guidedConversationOption.mostRecentInquiry }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="chatBotType == 'Keyword Driven'">
                    <p class="field column is-narrow pt-6">Keyword Responders with the appropriate action will need to be set up in the platform.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { secureGet, securePost, securePut } from '../secureFetch.js';

    export default {
        name: 'ChatBotLineSettings',
        props: {
            accountId: {
                type: String,
                default: null
            },
            lineId: {
                type: String,
                default: null
            },
            isBotEnabledOnLoad: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            hostUrl: function () {
                return this.$store.state.hostUrl;
            },
            guidedConversationsLoaded: function () {
                return this.guidedConversations.length > 0;
            }
        },
        data() {
            return {
                errorMessage: "",
                defaultErrorMessage: "Something didn't work.",
                isEnabled: false,
                chatBotTypeDropdownActive: false,
                chatBotType: "Select",
                chatBotTypeOptions: ["Catch-All", "Keyword Driven"],
                guidedConversationDropdownActive: false,
                guidedConversation: {
                    description: "Select"
                },
                guidedConversations: [],
                searchedGuidedConversations: [],
                loadedChatBotDetails: {},
                search: "",
                searchLoaded: false
            }
        },
        watch: {
            errorMessage(value) {
                this.$emit("error", value);
            },
            guidedConversations() {
                this.getChatBotDetails();
            },
            isBotEnabledOnLoad(value) {
                this.isEnabled = value;
            },
            isEnabled(value) {
                this.$emit("isBotEnabled", value);
                if (value == true && !this.guidedConversationsLoaded) {
                    this.getGuidedConversations();
                }
            },
            search(value) {
                this.searchedGuidedConversations = this.guidedConversations.filter((x) => x.mostRecentInquiry.toLowerCase().includes(value.toLowerCase()));

                if (!this.guidedConversationDropdownActive && this.searchLoaded) {
                    this.guidedConversationDropdownActive = true;
                } else {
                    this.searchLoaded = true;
                }
            }
        },
        methods: {
            delayChatBotTypeDropdown() {
                setTimeout(() => this.chatBotTypeDropdownActive = false, 500);
            },
            delayGuidedConversationDropdown() {
                setTimeout(() => this.guidedConversationDropdownActive = false, 500);
            },
            setChatBotType(chatBotType) {
                this.chatBotType = chatBotType;
            },
            setGuidedConversation(guidedConversation) {
                this.guidedConversation = guidedConversation;
                this.search = this.guidedConversation.mostRecentInquiry;
            },
            initializeChatBotDetails(chatBotDetails) {
                if (chatBotDetails.chatBotType == 1) {
                    this.chatBotType = "Catch-All";
                    this.loadedChatBotDetails.chatBotType = "Catch-All";
                    this.loadedChatBotDetails.externalChatBotId = chatBotDetails.externalChatBotId;
                    this.loadedChatBotDetails.catchAllChatBotId = chatBotDetails.catchAllChatBotId;
                    const guidedConversation = this.guidedConversations.find((x) => x.exchangeId == chatBotDetails.externalChatBotId);
                    this.setGuidedConversation(guidedConversation);
                } else if (chatBotDetails.chatBotType == 2) {
                    this.chatBotType = "Keyword Driven";
                    this.loadedChatBotDetails.chatBotType = "Keyword Driven";
                }
            },
            getChatBotDetails() {
                this.isLoading = true;
                secureGet(this.hostUrl + "v1/chatbot/chatbot/" + this.lineId)
                    .then((data) => {
                        this.isLoading = false;
                        if (data.success) {
                            this.initializeChatBotDetails(data);
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                    });
            },
            getGuidedConversations() {
                this.isLoading = true;
                secureGet(this.hostUrl + "v1/chatbot/guided-conversations/" + this.accountId)
                    .then((data) => {
                        this.isLoading = false;
                        if (data.success) {
                            this.guidedConversations = data.guidedConversations;
                            this.searchedGuidedConversations = data.guidedConversations;
                        } else {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                    });
            },
            createCatchAllChatBot() {
                this.isLoading = true;

                const request = {
                    lineId: this.lineId,
                    externalChatBotId: this.guidedConversation.exchangeId,
                    accountId: this.accountId
                };

                securePost(this.hostUrl + "v1/chatbot/catchall-chatbot", request)
                    .then((data) => {
                        this.isLoading = false;
                        if (data.success) {
                            this.loadedChatBotDetails.externalChatBotId = data.catchAllChatBot.externalChatBotId;
                            this.loadedChatBotDetails.catchAllChatBotId = data.catchAllChatBot.id;
                        } else {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            updateCatchAllChatBot() {
                this.isLoading = true;
                const request = {
                    id: this.loadedChatBotDetails.catchAllChatBotId,
                    externalChatBotId: this.guidedConversation.exchangeId
                };

                securePut(this.hostUrl + "v1/chatbot/catchall-chatbot", request)
                    .then((data) => {
                        this.isLoading = false;
                        if (data.success) {
                            this.loadedChatBotDetails.externalChatBotId = this.guidedConversation.exchangeId;
                        } else {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            save() {
                /* We'll only save a catch-all chat bot if...
                 * 1) The chat bot is enabled
                 * 2) The chat bot is validated
                 * 3) The chat bot type is catch-all
                 */
                let chatBotIsValid = false;

                if (!this.isEnabled || this.chatBotType == "Keyword Driven") {
                    chatBotIsValid = true;
                } else if (this.validateChatBotDetails() && this.chatBotType == "Catch-All") {
                    chatBotIsValid = true;
                    if (this.loadedChatBotDetails.externalChatBotId == null) {
                        this.createCatchAllChatBot();
                    } else {
                        this.updateCatchAllChatBot();
                    }
                }

                return chatBotIsValid;
            },
            validateChatBotDetails() {
                let valid = true;

                if (this.chatBotType == "Select") {
                    this.errorMessage = "Chat Bot Type required.";
                    valid = false;
                } else if (this.chatBotType == "Catch-All") {
                    if (this.guidedConversation.id == null) {
                        this.errorMessage = "Guided Conversation required.";
                        valid = false;
                    } else if (this.loadedChatBotDetails.externalChatBotId == this.guidedConversation.exchangeId) {
                        this.errorMessage = "Nothing to update for chat bot."
                        valid = false;
                    }
                }

                return valid;
            }
        }
    };
</script>

<style scoped> 
    .height-cap {
        max-height: 21em;
        overflow-y: auto;
    }
    .searchable-dropdown {
        min-width: 30em;
        text-align: left;
    }
</style>