<template>
  <div>
    <h4 class="subtitle is-4 mt-3 mb-2">Platform Settings</h4>
    <div class="field column is-narrow is-align-self-flex-end mb-2">
      <label class="switch-label">
        <input type="checkbox" class="switch" v-model="allowMessageText" @change="updateOptions"/> Enable Compose Message in Pop Out
      </label>
    </div>
    <div class="field column is-narrow is-align-self-flex-end mb-2">
      <label class="switch-label">
        <input type="checkbox" class="switch" v-model="allowMessageAttachment" @change="updateOptions"/> Enable Adding Attachments in Pop Out
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlatformSettings',
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    value: {
      handler() {
        this.allowMessageText = (this.value & 1) == 1;
        this.allowMessageAttachment = (this.value & 2) == 2;
      }
    }
  },
  mounted() {
    this.allowMessageText = (this.value & 1) == 1;
    this.allowMessageAttachment = (this.value & 2) == 2;
  },
  data() {
    return {
      allowMessageText: false,
      allowMessageAttachment: false,
    };
  },
  methods: {
    updateOptions() {
      const options = (this.allowMessageText ? 1 : 0) | (this.allowMessageAttachment ? 2 : 0);
      this.$emit('input', options);
    }
  },
}
</script>