<template>
  <div>
    <div class="control has-top-border">
      <h1 class="title mt-3">Meevo Line Settings</h1>
    </div>
    <div class="columns">
      <div class="field column is-half">
        <label class="label">Location Id</label>
        <div class="control">
          <input
            class="input"
            placeholder="Location Id..."
            v-model="locationId"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { secureGet } from "../secureFetch.js";

export default {
  name: "MeevoLineSettings",
  props: {
    publicCustomerLineId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    hostUrl: function () {
      return this.$store.state.hostUrl;
    },
  },
  data() {
    return {
      errorMessage: "",
      defaultErrorMessage: "Something didn't work.",
      isEnabled: false,
      meevoLineIntegration: null,
      locationId: null,
    };
  },
  watch: {
    errorMessage(value) {
      this.$emit("error", value);
    },
    locationId(value) {
      this.$emit("meevoLocationId", value);
    },
  },
  mounted() {
    if (this.publicCustomerLineId) {
      this.getMeevoLineIntegration();
    }
  },
  methods: {
    getMeevoLineIntegration() {
      this.isLoading = true;

      secureGet(
        this.hostUrl +
          "v1/meevo/line-integration?publicCustomerLineId=" +
          this.publicCustomerLineId
      ).then((data) => {
        if (data && data.success) {
          this.locationId = data.meevoLineIntegration.locationId;
          this.$emit("currentMeevoLocationId", this.locationId);
        }

        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped></style>
